<template>
  <v-card
    class="user-profile"
    :loading="loading"
  >
    <v-card-title class="text-subtitle-1 text-overline pr-12">
      Editar Meus Dados
    </v-card-title>
    <v-btn
      fab
      icon
      small
      absolute
      top
      right
      :disabled="loading"
      color="grey"
      class="mt-7 mr-n2"
      @click="close(true)"
    >
      <v-icon>
        {{ icons.close }}
      </v-icon>
    </v-btn>
    <v-card-text class="user-content text-center py-8 scrollable">
      <v-avatar 
        color="accent" 
        size="64"
        class="mb-6"
      >
        <span class="white--text text-h5 text-uppercase font-weight-medium">{{ data | initials }}</span>
      </v-avatar>
      <v-text-field
        v-model="data.username"
        readonly
        label="Email"
        outlined
        hide-details
        ref="email"
        class="my-6"
      >
        <template v-slot:append>
          <v-btn
            text
            small
            :color="controller.password.toggle ? 'grey' : 'accent'"
            @click="togglePassword"
          >
            {{ controller.password.toggle ? 'Cancelar' : 'Alterar senha' }}
          </v-btn>
        </template>
      </v-text-field>
      <v-expand-transition>
        <v-text-field
          v-show="controller.password.toggle"
          v-model="controller.password.data"
          :rules="controller.password.rules"
          :disabled="disabled||controller.password.disabled||loading"
          label="Nova Senha"
          outlined
          hide-details="auto"
          ref="password"
          class="mb-6"
        />
      </v-expand-transition>
      <v-text-field
        v-model="controller.name.data"
        :rules="controller.name.rules"
        :disabled="disabled||controller.name.disabled||loading"
        label="Nome Completo"
        outlined
        hide-details="auto"
        ref="name"
        class="mb-6"
      />
      <v-text-field
        v-model="controller.phone.data"
        v-mask="controller.phone.mask"
        :rules="controller.phone.rules"
        :disabled="disabled||controller.phone.disabled||loading"
        label="Telefone"
        outlined
        hide-details="auto"
        ref="phone"
        class="mb-6"
      />
      <v-card
        outlined
        class="notification-field mb-2"
      >
        <v-subheader 
          class="text-overline black--text pl-4 pr-2 py-1"
        >
          Notificações
          <v-spacer />
          <v-switch
            v-model="controller.notification.data"
            :false-value="0"
            :true-value="1"
            inset
            color="success"
            v-bind="attrs"
            v-on="on"
          />
        </v-subheader>
      </v-card>
    </v-card-text>
    <v-card-actions 
      class="pr-6 py-4"
    >
      <v-btn
        text
        color="grey"
        :disabled="loading"
        @click="close"
      >
        {{ !(updated&&changed) ? 'Voltar' : 'Cancelar' }}
      </v-btn>
      <v-spacer />
      <v-btn
        depressed
        color="primary"
        :disabled="disabled||!changed||!validated"
        :loading="loading"
        class="px-4"
        @click="save"
      >
        {{ updated&&!changed&&saved ? 'Salvo' : 'Salvar' }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<style lang="scss">

.user-profile .v-input__append-inner {
  margin-top: 14px !important;
}

</style>

<script>

  import { 
    mdiClose,
    mdiAlertCircle
  } from '@mdi/js';
  import { mask } from 'vue-the-mask'

  export default {
    props: {
      data: {
        type: Object,
        default: () => {}
      },
      disabled: {
        type: Boolean,
        default: false
      },
      loading: {
        type: Boolean,
        default: false
      },
      updated: {
        type: Boolean,
        default: false
      }
    },
    
    data: () => ({
      icons: {
        close: mdiClose,
        alert: mdiAlertCircle
      },
      controller: {
        name: {
          data: null,
          changed: false,
          disabled: false,
          loading: false,
          rules: [v => v!=null && v!='' || 'Campo obrigatório'],
        },
        phone: {
          data: null,
          changed: false,
          disabled: false,
          loading: false,
          mask: '(##) #####-####',
          rules: [v => ((v==null||v=='')||/^(?:\()[0-9]{2}(?:\))\s?[0-9]{4,5}(?:-)[0-9]{4}$/.test(v)) || 'Formato de telefone válido'],
        },
        password: {
          data: null,
          toggle: false,
          changed: false,
          disabled: false,
          loading: false,
          rules: [v => ((v==null||v=='')||v.length>=6) || 'Sua senha deve ter ao menos 6 caracteres'],
        },
        notification: {
          data: null,
          changed: false,
          disabled: false,
          loading: false,
        },
      },
      changed: false,
      saved: false,
    }),

    computed: {
      validated () {
        const data = _.clone(this.controller);
        return _.every(data, (data, f) => {
          return this.checkRules(this.rules, data);
        });
      }
    },

    watch: {
      controller: {
        deep: true,
        handler (controller) {
          const fields = _.cloneDeep(controller);
          _.each(fields, (d, f) => {
            if (!_.isEqual(this.data[f], d.data)) console.log('changed', f, this.data[f], d.data)
            this.controller[f].changed = !_.isEqual(this.data[f], d.data);
          })
          console.log(_.some(this.controller, ['changed', true]))
          this.changed = _.some(this.controller, ['changed', true]);
        }
      },
      data: {
        immediate: true,
        deep: true,
        handler (data) {
          console.log('user data', data);
          
          this.$nextTick(() => {
            if (this.changed&&this.updated) this.saved = true;

            _.each(_.clone(data), (d, f) => {
              if (_.has(this.controller, f)) {
                this.controller[f].data = d;
                this.controller[f].changed = false;
              }
            });
            
            this.changed = false;
          })
        }
      },
    },

    methods: {
      save () {
        setTimeout($ => {  
          console.log('saveUser', $.controller);
          const data = _.reduce(_.cloneDeep($.controller), (data, field, f) => {
            return data.id=='new' || field.changed ? { [f]: field.data, ...data } : data;
          }, { id: $.data.id });

          this.confirm = false;
          this.saved = false;
          this.$emit('save', data);
        }, 250, this);
      },

      togglePassword () {
        this.controller.password.toggle = !this.controller.password.toggle;
        setTimeout(($) => {
          if ($.controller.password.toggle) {
            $.$refs.password.$el.focus();
          }
        }, 1000, this);
      },

      close (force, confirm) {
        // if (force===true||_.isNil(this.controller.advertisers.selected)) {
        //   if (!this.changed||confirm===true) {
        //     this.controller.advertisers.selected = null;
        //     this.controller.advertisers.contract = null;
        //     this.confirm = false;
        this.$emit('toggle', false);
      },
      
      checkRules (rules, value) {
        return _.every(rules, rule => rule(value)==true);
      },
    },

    filters: {
      initials (user) {
        return !_.isNil(user) && !_.isNil(user.username) ? user.username.substring(0,1) : '';
      },
    },

    directives: {
      mask,
    },
  }
</script>